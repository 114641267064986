import React, { useContext, useState } from 'react';
import {
  CssBaseline,
  Box,
  Toolbar,
  Menu,
  MenuItem,
  Container,
} from '@mui/material';
import {
  createTheme, ThemeProvider,
} from '@mui/material/styles';
import {
  ExitToApp,
} from '@mui/icons-material';
import {
  Outlet,
} from 'react-router-dom';
import { signOutHandle } from '../../configs/auth';
import { UserContext } from '../../providers/userProvider';
import AppBar from './AppBar';
import Loader from '../Loader';
import Drawer from './Drawer';
import './Dashboard.css';

const mdTheme = createTheme();

function DashboardContent() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { loading } = useContext(UserContext);
  const menuId = 'primary-search-account-menu';
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ mt: 5 }}
    >
      <MenuItem onClick={() => { signOutHandle(); handleMenuClose(); }} sx={{ m: '2px' }}>
        <ExitToApp sx={{ fontSize: 20, mr: 4 }} />
        {' '}
        <span style={{
          marginRight: 40, fontWeight: 600, lineHeight: '1rem', fontSize: '14px',
        }}
        >
          Log Out
        </span>
      </MenuItem>
    </Menu>
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar handleProfileMenuOpen={handleProfileMenuOpen} />
        {renderMenu}
        <Drawer />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container sx={{ minWidth: '100%' }} className="Dashboard-wrapper">
            <Outlet />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
