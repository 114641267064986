import { initializeApp } from 'firebase/app';
import {
  getAuth, signInWithPopup, GoogleAuthProvider, signOut,
} from 'firebase/auth';
import configuration from './configuration';

const firebaseConfig = {
  apiKey: configuration.firebaseApiKey,
  authDomain: configuration.firebaseAuthDomain,
  databaseURL: configuration.firebaseDatabaseURL,
  projectId: configuration.firebaseProjectId,
  storageBucket: configuration.firebaseStorageBucket,
  messagingSenderId: configuration.firebaseMessagingSenderId,
  appId: configuration.firebaseAppId,
  measurementId: configuration.firebaseMeasurementId,
};

const app = initializeApp(firebaseConfig);

function googleLogin() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then(() => {
      console.log('sign in');
    })
    .catch((error) => {
      console.error(error);
    });
}

function signOutHandle() {
  const auth = getAuth(app);
  signOut(auth)
    .then(() => {
      console.log('sign out');
    })
    .catch((error) => {
      console.error(error);
    });
}
const auth = getAuth(app);

export { auth, googleLogin, signOutHandle };
export default app;
