import React from 'react';
import PropTypes from 'prop-types';
import { onAuthStateChanged } from 'firebase/auth';
import Loader from '../../components/Loader';
import { auth } from '../../configs/auth';

export const AuthContext = React.createContext('user');
function AuthProvider({ children }) {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const unsuscribe = onAuthStateChanged(auth, (authUser) => {
      setLoading(true);
      if (authUser) {
        if (!(authUser.email.includes('successive.tech')
      || authUser.email.includes('successivesoftwares.com'))) {
          throw (new Error('Not belong to the organisation'));
        }
        setUser(authUser);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => {
      unsuscribe();
    };
  });

  const authDetail = React.useMemo(() => ({ user }), [user]);

  if (loading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={authDetail}>{children}</AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
