import React, { Suspense, useContext, useEffect } from 'react';
import {
  Routes, Route, useNavigate, useLocation, Navigate,
} from 'react-router-dom';
import { Dashboard, Loader } from '../components';
import { AuthContext } from '../providers/authProvider';
import { AuthRequiredRoutes, AuthNotRequiredRoutes } from './routesKey';

function RoutesComponent() {
  const userDetail = useContext(AuthContext);
  const { user } = userDetail;
  const { accessToken } = user || { accessToken: '' };
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (accessToken) {
      navigate(
        location?.state?.prevPath
          || AuthRequiredRoutes.find(({ path }) => path === location.pathname)?.path
          || '/',
        { replace: true },
      );
    } else {
      navigate('/login', { replace: true, state: { prevPath: location.pathname } });
    }
  }, [accessToken]);

  return (
    <Routes>
      {accessToken && (
        <Route
          element={(
            <Dashboard />
          )}
        >
          {AuthRequiredRoutes.map(({ name, path, Component }) => (
            <Route
              key={name}
              index
              exact
              path={path}
              element={(
                <Suspense fallback={<Loader />}>
                  <Component />
                </Suspense>
              )}
            />
          ))}
          <Route
            key="0"
            path="*"
            element={(
              <Suspense fallback={<Loader />}>
                <Navigate
                  to={
                    location?.state?.prevPath
                    || AuthRequiredRoutes.find(({ path }) => path === location.pathname)?.path
                    || '/'
                  }
                  replace
                />
              </Suspense>
            )}
          />
        </Route>
      )}
      {!accessToken && (
        <>
          {AuthNotRequiredRoutes.map(({ name, path, Component }) => (
            <Route
              key={name}
              index
              exact
              path={path}
              element={(
                <Suspense fallback={<Loader />}>
                  <Component />
                </Suspense>
              )}
            />
          ))}
        </>
      )}
      <Route
        key="1"
        path="*"
        element={(
          <Suspense fallback={<Loader />}>
            <Navigate to="/login" replace state={{ prevPath: location.pathname }} />
          </Suspense>
        )}
      />
    </Routes>
  );
}

export default RoutesComponent;
