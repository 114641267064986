export default {
  firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  firebaseDatabaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  firebaseMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
  firebaseMeasurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  redmineTicketUrl: process.env.REACT_APP_REDMINE_TICKET_URL,
  resourceApiUrl: process.env.REACT_APP_RESOURCE_API_URL,
  projectApiUrl: process.env.REACT_APP_PROJECT_API_URL,
  fetchResourceAPiUrl: process.env.REACT_APP_FETCH_RESOURCE_API_URL,
};
