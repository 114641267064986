import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Loader() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: '40vh' }}>
      <CircularProgress size={70} />
    </Box>
  );
}

export default function CircularLoader() {
  return <Loader />;
}
