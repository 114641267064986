/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useContext } from 'react';
import './App.css';
import Routes from './router';
import { Loader } from './components';
import { UserContext } from './providers/userProvider';

function App() {
  const userDetail = useContext(UserContext);
  const { loading } = userDetail;

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
