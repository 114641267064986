import React from 'react';
import PropTypes from 'prop-types';

function ErrorComponent() {
  return <h1>Something went wrong</h1>;
}
class ErrorBoundary extends React.Component {
  constructor() {
    super();
    this.state = {

      hasError: false,
      error: { message: '', stack: '' },
      info: { componentStack: '' },
    };
  }

  static getDerivedStateFromError = (error) => {
    console.error(error);
    return ({ hasError: true });
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    const { hasError, error, info } = this.state;
    console.log(error, info);
    const { children } = this.props;

    return hasError ? <ErrorComponent /> : children;
  }
}

ErrorBoundary.defaultProps = {
  children: null,
};

ErrorBoundary.propTypes = {
  children: PropTypes.element,
};

export default ErrorBoundary;
