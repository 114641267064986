import React from 'react';
import {
  Box,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
} from '@mui/material';
import {
  styled,
} from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
// import { ROLESNAME } from '../../utils/constant';
import { AuthContext } from '../../providers/authProvider';
// import { UserContext } from '../../providers/userProvider';

const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'white',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

function AppBar(props) {
  const { handleProfileMenuOpen } = props;
  const userDetail = React.useContext(AuthContext);
  // const { userRole } = React.useContext(UserContext);
  const { user: { displayName } } = userDetail;
  // const { roles: [roleName] } = userRole;
  const nameArray = displayName?.split(' ');
  const photoVariable = `${nameArray[0][0]}${nameArray[1][0]}`;
  const menuId = 'primary-search-account-menu';

  return (
    <CustomAppBar component="nav" position="fixed">
      <Toolbar>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          <img src="successivelogo.svg" alt="successive-logo" />
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Avatar sx={{ bgcolor: '#2559c3!important' }}>
            <b style={{ fontSize: '15px' }}>{photoVariable}</b>
          </Avatar>
          <Box sx={{ ml: 1.5, alignItems: 'center' }}>
            <Typography
              style={{
                color: 'black',
                fontWeight: 900,
                fontSize: '14px',
                lineHeight: 1.2,
              }}
            >
              {displayName}
            </Typography>
            <Typography style={{ color: 'black', fontSize: '12px' }}>
              {/* {ROLESNAME[roleName] || roleName} */}
            </Typography>
          </Box>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <KeyboardArrowDownIcon style={{ color: 'black', marginLeft: 2 }} />
          </IconButton>
        </Box>
      </Toolbar>
    </CustomAppBar>
  );
}

AppBar.propTypes = {
  handleProfileMenuOpen: PropTypes.func.isRequired,
};

export default AppBar;
