import React from 'react';

const Login = React.lazy(() => import('../modules/Login'));
const Home = React.lazy(() => import('../modules/org_dashboard/Home'));
const Admin = React.lazy(() => import('../modules/admin'));

const AuthRequiredRoutes = [
  {
    path: '/',
    name: 'Org Dashboard',
    Component: Home,
  },
  {
    path: '/home',
    name: 'Home',
    Component: () => <div />,
  },
  {
    path: '/admin',
    name: 'Admin',
    Component: Admin,
  },
];

const AuthNotRequiredRoutes = [
  {
    path: '/login',
    name: 'login',
    Component: Login,
  },
];

export { AuthRequiredRoutes, AuthNotRequiredRoutes };
