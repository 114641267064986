import React, { useContext, useMemo } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import configuration from '../../configs/configuration';
import { AuthContext } from '../authProvider';

export const UserContext = React.createContext('user');
function UserProvider({ children }) {
  const [userRole, setUserRole] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { user } = useContext(AuthContext);

  React.useEffect(() => {
    const { accessToken } = user || { accessToken: '' };
    const timeOutId = setTimeout(() => {
      if (accessToken) {
        setLoading(true);
        axios.get(`${configuration.resourceApiUrl}/get-user-role-permissions`, { headers: { Authorization: accessToken } }).then((res) => {
          setUserRole(res.data.data);
          setLoading(false);
        }).catch((error) => {
          console.error('Error in get-user-role-permissions: ', error);
        });
      } else {
        setUserRole(null);
      }
    });

    return () => clearTimeout(timeOutId);
  }, [user]);

  const userDetail = useMemo(() => ({ userRole, loading }), [userRole, loading]);

  return (
    <UserContext.Provider value={userDetail}>{children}</UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
