import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  Button,
  CardContent,
} from '@mui/material';
import {
  styled,
} from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer';
import {
  Home,
} from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const drawerWidth = 120;

const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: '#2559c3!important',
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

function Drawer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  return (
    <CustomDrawer variant="permanent" open sx={{ height: '100vh' }}>
      <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Grid xs={12} sx={{ mt: 8 }}>
          <Card
            elevation={0}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...({
                backgroundColor: `${pathname === '/' ? '#578cf9' : '#2559c3'}!important`,
              }),
            }}
          >
            <Button
              onClick={() => navigate('/')}
              sx={{ alignContent: 'center', height: '100px' }}
            >
              <CardContent sx={{ alignContent: 'center', padding: 0 }}>
                <Home sx={{ fontSize: '25px', color: 'white' }} />
                <Typography sx={{
                  color: 'white', fontSize: '12px', fontFamily: 'Lato', fontStyle: 'normal',
                }}
                >
                  Org Dashboard
                </Typography>
              </CardContent>
            </Button>
          </Card>
        </Grid>
        <Grid
          xs={12}
        >
          <Card
            elevation={0}
            sx={{
              height: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...({
                backgroundColor: `${pathname === '/admin' ? '#578cf9' : '#2559c3'}!important`,
              }),
            }}
          >
            <Button
              onClick={() => navigate('/admin')}
              sx={{ alignContent: 'center', width: '100%', height: '100px' }}
            >
              <CardContent sx={{ alignContent: 'center', padding: 0 }}>
                <AdminPanelSettingsIcon sx={{ fontSize: '25px', color: 'white' }} />
                <Typography sx={{
                  color: 'white', fontSize: '12px', fontFamily: 'Lato', fontStyle: 'normal',
                }}
                >
                  Admin
                </Typography>
              </CardContent>
            </Button>
          </Card>
        </Grid>
      </Box>
    </CustomDrawer>
  );
}

export default Drawer;
